.Dashboard {
  height: 100%;
}

.Dashboard .title-section {
  margin: 12px;
}

.Dashboard .title-section .logo {
  width: 35px;
  height: 35px;
}
.Dashboard .title-section .title {
  color: #fff;
  margin: 0;
}

.Dashboard .trigger {
  margin: 0 16px;
}

.Dashboard .header .user-section {
  position: absolute;
  right: 0;
  padding: 0px 16px;
}